<template>
    <div class="video_room-container">
        <BreadcrumbNav :page-name="$t('project.videoCall')"></BreadcrumbNav><!--视频通话-->
        <div class="main">
            <div class="op-bar">

                <el-button @click.native="leave" class="fr" plain type="info">{{$t('project.leaveCurrentChannel')}}
                    <!--离开当前频道--></el-button>
                <el-button :disabled="$route.query.isPublisher==0" @click.native="endInterview" class="fr" plain
                           type="info">
                    {{$t('project.endCurrentInterview')}}<!--结束当前访谈-->
                </el-button>
            </div>
            <div class="video-area">
                <div class="video-window fl">
                    <div class="title">{{ localName }}</div>
                    <div class="player local-player" id="local-player"></div>
                </div>
                <div :id="playerWrapperId(item.uid)" :key="index" class="video-window fl"
                     v-for="(item ,index) in remotePlayerList">
                    <div class="title">{{$t('project.remoteUser')}}<!--远程用户--> （{{ item.uid }}）</div>
                    <div :id="playerId(item.uid)" class="player"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import AgoraRTC from "agora-rtc-sdk-ng"
    import {endShengwang, startVideo} from "@/api/video_talk"
    import {endInterview, myParticipateList} from "@/api/myProjectApi";

    let client = null;
    export default {
        name: "video_room",
        data() {
            return {
                remotePlayerList: [],
                localName: '',
                //本地音轨
                localTracks: {
                    videoTrack: null,
                    audioTrack: null,
                },
                remoteUsers: {},//远程用户
                rtc: {
                    // 用来放置本地音视频频轨道对象。
                    localAudioTrack: null,
                    localVideoTrack: null,
                },
                //客户端选项
                options: {
                    uid: null,
                    // 替换成你自己项目的 App ID。
                    appId: "76cc11d2797945f98a1ac8fd4821958b",
                    // 传入目标频道名。
                    channel: "mychannel",
                    // 如果你的项目开启了 App 证书进行 Token 鉴权，这里填写生成的 Token 值。
                    token: "00676cc11d2797945f98a1ac8fd4821958bIAAqIJB983lmWDCsRz+m26nYaeCgXCQfRVHE+DYPzOeN9t+pr8cAAAAAEACESFKsepV6YAEAAQB4lXpg",
                },
            };
        },
        components: {
            BreadcrumbNav,
        },
        async mounted() {
            this.startVideo();

        },
        methods: {
            //接口

            async joinHandle() {
                console.log("启动声网SDK")
                try {
                    await this.join();
                    if (this.options.token) {
                        //加入房间成功
                        this.$message.success(this.$t('project.joinedRoom'))
                    } else {
                        this.$message.info("other")
                    }
                } catch (error) {
                    this.$message.error(error)
                }
            },
            async join() {
                // 添加事件侦听器以在远程用户发布时播放远程轨道。
                client.on("user-published", this.handleUserPublished);
                client.on("user-unpublished", this.handleUserUnpublished);

                //加入一个频道并创建本地轨道，我们可以使用答应我。好的同时运行它们
                [this.options.uid, this.localTracks.audioTrack, this.localTracks.videoTrack] = await Promise.all([
                    // 加入通道
                    client.join(this.options.appid, this.options.channel, this.options.token || null),
                    // 使用麦克风和摄像头创建本地轨道
                    AgoraRTC.createMicrophoneAudioTrack(),
                    AgoraRTC.createCameraVideoTrack()
                ]);

                // 播放本地视频轨道
                this.localTracks.videoTrack.play("local-player");

                this.localName = `本地用户uid:(${this.options.uid})`

                // 将本地轨道发布到频道
                await client.publish(Object.values(this.localTracks));
                console.log("发布成功");
            },
            async leave() {
                for (let trackName in this.localTracks) {
                    var track = this.localTracks[trackName];
                    if (track) {
                        track.stop();
                        track.close();
                        this.localTracks[trackName] = undefined;
                    }
                }

                // 删除远程用户和播放器视图
                this.remoteUsers = {};
                this.remotePlayerList = [];

                // 离开频道
                await client.leave();

                this.localName = ``
                this.$message.success(this.$i18n == 'zh' ? "客户端离开频道成功！" : "The client left the channel successfully!")
                console.log("客户端离开频道成功！");
                this.$router.go(-1);
            },

            async subscribe(user, mediaType) {
                const uid = user.uid;
                // 订阅远程用户
                await client.subscribe(user, mediaType);
                console.log("订阅成功");
                if (mediaType === 'video') {
                    await this.remotePlayerList.push({uid: uid});
                    user.videoTrack.play(`player-${uid}`);
                }
                if (mediaType === 'audio') {
                    user.audioTrack.play();
                }
            },

            //监听事件（远程用户加入时）
            handleUserPublished(user, mediaType) {
                const id = user.uid;
                this.remoteUsers[id] = user;
                this.subscribe(user, mediaType);
                this.$notify({
                    title: this.$i18n == 'zh' ? "远程用户加入！" : 'Remote user join!',
                    message: this.$i18n == 'zh' ? '进入当前频道' : "Enter current channel",
                });
                console.log(user)
            },
            //监听事件（远程用户离开时）
            handleUserUnpublished(user) {
                const id = user.uid;
                delete this.remoteUsers[id];
                for (let i = 0; i < this.remotePlayerList.length; i++) {
                    if (this.remotePlayerList[i].uid == id) {
                        this.remotePlayerList.splice(i, 1)
                    }
                }
                // $(`#player-wrapper-${id}`).remove();
            },


            playerWrapperId(index) {
                return "player-wrapper-" + index
            },
            playerId(index) {
                return "player-" + index
            },

            //获取账户信息
            async startVideo() {
                let formData = {
                    token: localStorage.getItem('token'),
                    interviewId: this.$route.query.interviewId,
                    type: this.$route.query.type,
                    inviteType: this.$route.query.inviteType,
                };
                this.isLoading = true;
                let res = await startVideo(formData);
                if (res.code == 200) {
                    console.log("获取房间必要参数", res.data)
                    this.options.appId = res.data.APPID;
                    this.options.channel = res.data.channelName;
                    this.options.token = res.data.token;
                    //启动声网SDK
                    client = await AgoraRTC.createClient({mode: "rtc", codec: "vp8"});
                    await this.joinHandle();
                } else {
                    this.$message.error(res.msg)
                }
            },

            //获取账户信息
            endInterview() {
                let formData = {
                    interview_id: this.$route.query.interviewId,
                    type: this.$route.query.type,
                }
                endInterview(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.leave()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .video_room-container {
        min-height: calc(100vh - 182px);
    }

    .main {
        width: 1200px;
        margin: 0 auto;
    }

    .op-bar {
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        overflow: hidden;
    }

    .video-area {
        width: 100%;
    }

    .video-area .video-window {
        width: 400px;

    }

    .video-area .video-window .title {
        box-sizing: border-box;
        width: 400px;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        background-color: white;
    }

    .video-area .video-window .player {
        width: 400px;
        height: 260px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }

    .video-area .video-window .local-player {
        box-shadow: 0 2px 4px rgba(0, 191, 255, .82), 0 0 6px rgba(0, 191, 255, .04)
    }
</style>
