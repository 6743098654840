import request from "@/utils/request";


// 开始访谈(开始视频通话)
export function startVideo(data) {
    return request({
        url: '/shengwang/createProject',
        method: 'post',
        params: data
    })
}

// 结束访谈
export function endShengwang(data) {
    return request({
        url: '/shengwang/endShengwang',
        method: 'post',
        params: data
    })
}

